<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}

.my-table {
	max-height: 50vh;
	overflow-y: auto;
}

.my-label {
	color: red;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row class="container-layout px-0">
			<b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ this.caption1 }}
				</h1>
			</b-col>
			<b-col cols="12" xl="12">
				<b-overlay :show.sync="isBusyAllScreen" rounded="lg">
					<div class="animated fadeIn">
						<b-card no-body class="card-border-blue-light">
							<b-card-body class="form content-card-collapsible">
								<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0">
									<legend class="card-inside">Advanced search</legend>
									<b-row>
										<b-col cols="6">
											<b-form-group label="Choice" label-for="choice" :label-cols="4">
												<b-form-select
													v-model="currentChoice"
													:options="choiceOptions"
													@change="choiceChanged"
													size="md"
												></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group label="From English to" label-for="language" :label-cols="4">
												<b-form-select
													v-model="currentLanguage"
													:options="optionsForLanguage"
													@change="languageChanged"
													size="md"
												></b-form-select>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="currentChoice == 0">
										<b-col cols="6">
											<b-form-group label="Application" label-for="appChoice" :label-cols="4">
												<b-form-select
													v-model="currentApp"
													:options="Apps"
													id="appChoice"
													@change="appChanged"
													size="md"
												></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group label="Form" label-for="formChoice" :label-cols="4">
												<v-select
													v-model="currentForm"
													label="formName"
													:options="Forms"
													:reduce="(option) => option.formNumber"
													:clearable="false"
													@option:selected="formChanged"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</fieldset>
								<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-3 py-0" style="background-color: #ffffff">
									<b-alert :show="addedMessageAlert" dismissible fade variant="primary" @dismissed="closeAlert">
										<div class="d-flex flex-row justify-content-center align-items-center pb-2">
											<strong>-- Last New Message --</strong>
										</div>
										<div class="d-flex flex-row justify-content-start align-items-center pb-2">
											<b-col cols="4" class="pl-0">
												<div class="d-flex flex-row">
													<div class="pr-4">
														<div class="text-center">
															<strong>Number</strong>
														</div>
														<div class="text-center">{{ newMessageInfo.number }}</div>
													</div>
													<div class="pr-4" v-if="currentChoice !== 2">
														<div class="text-center">
															<strong>Value</strong>
														</div>
														<div class="text-center">{{ newMessageInfo.value }}</div>
													</div>
													<div v-if="currentChoice === 1">
														<div class="text-center">
															<strong>Factor</strong>
														</div>
														<div class="text-center">{{ newMessageInfo.factor }}</div>
													</div>
												</div>
											</b-col>
										</div>
										<div class="d-flex flex-row justify-content-start align-items-center">
											<div>
												<div class="text-left">
													<strong>Message</strong>
												</div>
												<div>&laquo; {{ newMessageInfo.message }} &raquo;</div>
											</div>
										</div>
									</b-alert>
									<legend class="card-inside">Translations</legend>
									<b-row>
										<b-col cols="9" class="pb-4">
											<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0">
												<legend class="card-inside">&laquo; {{ newLangres.message }} &raquo;</legend>
												<b-row>
													<b-col cols="12">
														<b-form-group :label="translatedLabel" label-for="msgTranslated" :label-cols="2">
															<b-form-textarea
																v-model="newLangres.translatedMessage"
																type="textarea"
																rows="2"
																autocomplete="off"
																id="msgTranslated"
																:class="{ 'is-invalid': isSubmitted && $v.newLangres.translatedMessage.$error }"
															/>
															<div v-if="isSubmitted && !$v.newLangres.translatedMessage.required" class="invalid-feedback">
																Message is required
															</div>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col cols="2"></b-col>
													<b-col>
														<b-row>
															<b-col :cols="numberCols">
																<b-form-group label="Number" label-for="resNumber" :label-cols="3">
																	<b-form-input v-model="newLangres.number" type="number" id="resNumber"></b-form-input>
																</b-form-group>
															</b-col>
															<b-col :cols="valueCols" v-if="currentChoice != 2">
																<b-form-group label="Value" label-for="resValue" :label-cols="3">
																	<b-form-input v-model="newLangres.value" type="number" id="resValue"></b-form-input>
																</b-form-group>
															</b-col>
															<b-col :cols="factorCols" v-if="currentChoice == 1">
																<b-form-group label="Factor" label-for="factor" :label-cols="3">
																	<b-form-input v-model="newLangres.paramFloat" type="number" id="factor"></b-form-input>
																</b-form-group>
															</b-col>
															<b-col :cols="helpCols" v-if="currentChoice == 1">
																<b-button id="show-modal" @click="showModal = true" class="pull-right"
																	><i class="fa fa-question-circle" />
																</b-button>
															</b-col>
														</b-row>
													</b-col>
													<div class="w-100 d-flex flex-row justify-content-end pb-3">
														<b-col cols="3">
															<b-button @click="newMessage" variant="outline-secondary" block>New</b-button>
														</b-col>
														<b-col cols="3">
															<b-button @click="curLangresUpd" variant="primary" :disabled="disableSave" block>Save</b-button>
														</b-col>
													</div>
												</b-row>
											</fieldset>
										</b-col>
										<b-col cols="3">
											<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0">
												<legend class="card-inside">Google</legend>
												<b-row>
													<b-col cols="12">
														<b-form-checkbox
															size="lg"
															v-model="valueGoogleSelected"
															@change="selectGoogleTranslation"
															class="pj-cb pb-2"
															value="all"
															>All items in all Languages</b-form-checkbox
														>
														<b-form-checkbox
															size="lg"
															v-model="valueGoogleSelected"
															@change="selectGoogleTranslation"
															class="pj-cb pb-3"
															:value="currentLanguage"
															>All items in {{ languageChosen }}</b-form-checkbox
														>
													</b-col>
												</b-row>
												<div class="w-100 d-flex flex-row justify-content pb-3" style="padding-top: 41px">
													<b-col cols="12">
														<b-button
															variant="primary"
															:disabled="disableGoogleTranslation"
															@click="doTranslation(valueGoogleSelected)"
															block
															>Translate</b-button
														>
													</b-col>
												</div>
											</fieldset>
										</b-col>
									</b-row>
								</fieldset>
								<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-3 pt-0 pb-3">
									<legend class="card-inside">Filters</legend>
									<b-row>
										<b-col cols="8" class="mt-1">
											<b-form-group
												label="Per page"
												label-for="per-page-select"
												label-cols-sm="2"
												label-cols-md="2"
												label-cols-lg="2"
												label-size="sm"
												class="mb-3"
											>
												<b-form-select
													id="per-page-select"
													v-model="perPage"
													:options="pageOptions"
													@change="changeNumberRows"
													size="sm"
												></b-form-select>
											</b-form-group>
											<b-form-group
												label="Filter"
												label-cols-sm="2"
												label-cols-md="2"
												label-cols-lg="2"
												label-align-sm="left"
												label-size="sm"
												label-for="filterInput"
												class="mb-0"
											>
												<!-- <b-input-group size="sm">
													<b-form-input
														v-model.trim="filter"
														@keydown.enter="search($event)"
														@keyup.delete="removeFilter"
														type="text"
														id="filterInput"
														placeholder="Type to search..."
														autocomplete="off"
														v-b-tooltip.focus
													></b-form-input>
													<b-input-group-append>
														<b-button :disabled="!filter" @click="clearFilter">
															<i class="fa fa-times" aria-hidden="true"></i>
														</b-button>
													</b-input-group-append>
													<b-tooltip target="filterInput" placement="top" triggers="focus"> Type 'Enter' to launch search </b-tooltip>
												</b-input-group> -->
												<b-input-group>
													<b-form-input
														v-model.trim="filter"
														@keydown.enter="search($event)"
														@keyup.delete="removeFilter"
														type="text"
														id="filterInput"
														placeholder="Type to search..."
														autocomplete="off"
														v-b-tooltip.focus
													/>
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getIconLucide('Search')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getIconLucide('X')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="clearFilter"
																v-else
															/>
														</b-input-group-text>
														<b-tooltip target="filterInput" placement="top" triggers="focus">
															Type 'Enter' to launch search
														</b-tooltip>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="4" class="d-flex justify-content-end align-items-center">
											<b-form-checkbox size="lg" class="pj-cb mt-1" v-model="showAllMessagesToVerify" @change="getShowAllMessagesToVerify"
												>Show all messages to verify</b-form-checkbox
											>
										</b-col>
									</b-row>
								</fieldset>
								<b-row>
									<b-col cols="6" xl="6">
										<b-pagination
											v-model="currentPage"
											:total-rows="totalRows"
											:per-page="perPage"
											align="fill"
											size="md"
											class="mt-1 mb-2"
											@change="onPaginated"
										></b-pagination>
									</b-col>
									<b-col cols="2" xl="2">
										<b-form-group label="Page n°" label-for="page-number" :label-cols="4">
											<b-form-input
												v-model="pageNumber"
												type="number"
												id="page-number"
												min="0"
												step="1"
												@keydown.enter="goToPage($event)"
											></b-form-input>
											<b-tooltip target="page-number" placement="top" triggers="focus"> Type 'Enter' to go on page number </b-tooltip>
										</b-form-group>
									</b-col>
									<b-col cols="4" class="d-flex justify-content-end align-items-center">
										<span style="color: #00af8b"
											>Total rows: <strong>{{ totalRows }}</strong></span
										>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="12" xl="12">
										<CardListBuilder v-if="$screen.width < 992" :items="Langres" :fields="fields" @row-clicked="rowClicked">
											<template slot="actions" slot-scope="data">
												<b-button v-if="isDev" variant="none" size="sm" @click="delItem(data.item)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.width >= 992"
											:hover="hover"
											class="my-table"
											responsive="sm"
											ref="myTable"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											:items="Langres"
											style="text-align: left"
											:fields="fields"
											sticky-header="stickyHeader"
											@row-clicked="rowClicked"
											:head-variant="hv"
											bordered
											small
										>
											<template v-slot:cell(toCheck)="data">
												<div class="cursor-pointer p-2">
													<b-form-checkbox
														v-model="data.item.toCheck"
														size="lg"
														@change="updateToCheckStatus(data.item, $event)"
														:disabled="data.item.translatedMessage === 'AA'"
													/>
												</div>
											</template>
											<template v-slot:cell(rem)="data">
												<b-button v-if="isDev" variant="none" size="sm" @click="delItem(data.item)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</b-table>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</div>
				</b-overlay>
			</b-col>
			<div v-if="showModal">
				<transition name="modal">
					<div class="modal-mask">
						<div class="modal-wrapper">
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<h4 class="modal-title">HELP</h4>
										<button type="button" class="close" @click="showModal = false">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body">
										<strong>Value</strong> is the value of the menu item, if you want to create a new entry and do not care about this
										value: enter -1, it will be generated automatically <br />
										<strong>Factor</strong> is the attribute for CO2 factor in KGCO2/€ (from menu 900) for each text potentially appearing
										in an expense, <br />
										or <br />
										<strong>Factor</strong> is the green category value (from menu 1016) for green tips categories related to menus number:
										<br />
										1020 for tip of day user <br />
										1021 for tip of day SME <br />
										1022 for tip of day FILM <br />
										1023 for tip of day AGENCY <br />
										1024 for tip of day HOTEL
										<br />

										<br />
										"GREEN", "value": "0" <br />
										"REDUCE/REUSE/RECYCLE TIPS", "value": "1" <br />
										"KITCHEN GREEN TIPS", "value": "2" <br />
										"ECO-FRIENDLY BATHROOM TIPS", "value": "3" <br />
										"HOME ENERGY SAVING TIPS", "value": "4" <br />
										"WATER-SAVING TIPS", "value": "5" <br />
										"ORGANIC GARDENING TIPS", "value": "6" <br />
										"GAS SAVING TIPS", "value": "7" <br />
										"GREEN TRAVEL TIPS", "value": "8" <br />
										"RESPONSIBLE ANIMAL ENCOUNTERS", "value": "9"
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</b-row>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { isNil } from '~utils';
import * as icons from 'lucide-vue';

import {
	GetAllFormsContentForTranslation,
	GetAllMessagesForTranslation,
	GetAllMenusContentForTranslation,
	AddUpdLangResItem,
	deleteFormItem,
	deleteLanguageItem,
	translateAllItems,
	getAllFormsNameOnly,
	searchtAllFormsContentForTranslation,
	searchAllMenusContentForTranslation,
	searchAllMessagesForTranslation,
	updateLanguageCheckStatus,
	translateLanguageItems
} from '@/cruds/language.crud';
import globalMixin from '@/mixins/global.mixin';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'Translations',
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			showModal: false,
			erreur: {},
			currentForm: {
				formNumber: 0,
				formName: 'All Forms'
			},
			webForm: true, // webapp=true mobileapp=false
			currentLanguage: 0,
			currentApp: 0, // webapp=0 mobileapp=1
			currentChoice: 0,
			choiceOptions: [
				{
					value: 0,
					text: 'Forms'
				},
				{
					value: 1,
					text: 'Menus'
				},
				{
					value: 2,
					text: 'Messages'
				}
			],
			Langres: [], // selected for display
			Apps: [
				{
					value: 0,
					text: 'Web'
				},
				{
					value: 1,
					text: 'Mobile'
				}
			],
			Forms: [],
			allForms: {
				formNumber: 0,
				formName: 'All Forms'
			},
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 20, 50, 100, 150, 200],
			warning: '',
			filter: '',
			newCreated: 0,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			stickyHeader: true,
			successModal: false,
			successModalDelete: false,
			curLangres: {},
			newLangres: {},
			numberCols: 3,
			valueCols: 3,
			factorCols: 3,
			helpCols: 3,
			isBusyAllScreen: false,
			languageChosen: '',
			valueGoogleSelected: [],
			currentTableIndex: 0,
			searchSubmitted: false,
			pageNumber: 0,
			stateMessageAction: 1, // add=0, update=1
			langresType: 4, // FORM_WEB=4, FORM_MOBILE=3, MENU=1, MESSAGE=0
			showAllMessagesToVerify: false,
			isSubmitted: false,
			newMessageInfo: {
				message: '',
				number: 0,
				value: 0,
				factor: 0
			},
			addedMessageAlert: false,
			copyLangres: {}
		};
	},
	computed: {
		isDev() {
			return store.isDev();
		},
		fields() {
			let ar = [
				{
					key: 'formName',
					label: this.firstColTitle(),
					sortable: true
				},
				{
					key: 'number',
					label: this.secondColTitle(),
					sortable: true,
					class: 'text-center'
				}
			];

			if (this.currentChoice !== 2) {
				ar.push({
					key: 'value',
					label: 'Value',
					sortable: true,
					class: 'text-center'
				});
			}

			if (this.currentChoice === 1) {
				ar.push({
					key: 'paramFloat',
					label: 'factor',
					sortable: true,
					class: 'text-center'
				});
			}

			ar.push({
				key: 'message',
				label: 'Message',
				sortable: true
			});

			ar.push({
				key: 'translatedMessage',
				label: 'Translation',
				sortable: true
			});

			ar.push({
				key: 'toCheck',
				label: 'To verify',
				sortable: false,
				formatter: (v) => v,
				class: 'text-center'
			});

			ar.push({
				key: 'rem',
				label: 'Remove',
				sortable: false,
				formatter: (v) => v,
				class: 'text-center'
			});

			return ar;
		},
		optionsForLanguage() {
			if (this.isDev) {
				return this.FormMenu(1);
			} else {
				this.currentLanguage = 1;
				let Menu = this.FormMenu(1);
				return Menu.filter((data) => data.value != 0);
			}
		},
		translatedLabel() {
			let str = 'Translate into ';
			for (let i = 0; i < this.optionsForLanguage.length; i++) {
				if (this.optionsForLanguage[i].value == this.currentLanguage) {
					str += this.optionsForLanguage[i].text;
					this.languageChosen = this.optionsForLanguage[i].text;
					break;
				}
			}

			return str;
		},
		caption1() {
			//return this.FormMSG(8,"Translations for form:");
			return 'Translations';
		},
		disableSave() {
			if (
				this.curLangres.translatedMessage != this.newLangres.translatedMessage ||
				this.curLangres.number != this.newLangres.number ||
				this.curLangres.value != this.newLangres.value ||
				this.curLangres.paramFloat != this.newLangres.paramFloat
			) {
				if ((this.curLangres.type == 1 && this.curLangres.number == 3) || (this.curLangres.type == 1 && this.curLangres.number == 6)) {
					// do not translate list of forms and web forms
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		},
		disableGoogleTranslation() {
			return this.valueGoogleSelected.length === 0;
		}
	},
	methods: {
		choiceChanged() {
			this.filter = '';
			this.pageNumber = 0;
			this.searchSubmitted = false;

			this.handleAction();
		},
		handleAction(pageNumber = 1) {
			if (this.currentChoice === 1) {
				this.langresType = 1;
			} else if (this.currentChoice === 2) {
				this.langresType = 0;
			} else {
				if (this.currentApp === 0) {
					this.langresType = 4;
				} else {
					this.langresType = 3;
				}
			}

			this.isBusyAllScreen = true;

			this.$nextTick(async () => {
				this.currentPage = pageNumber;
				this.calcManualTranslationCols(this.currentChoice);

				if (this.currentChoice === 1) {
					await this.loadMenus()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangres());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else if (this.currentChoice === 2) {
					await this.loadMessages()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangres());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else {
					await this.loadLangresForms()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangres());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				}
			});
		},
		searchAction(copyFilter = '') {
			this.isBusyAllScreen = true;
			this.$nextTick(async () => {
				this.currentPage = 1;
				// this.calcManualTranslationCols(e);

				if (this.currentChoice === 1) {
					await this.searchMenus()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangresOfSearch());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else if (this.currentChoice === 2) {
					await this.searchMessages()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangresOfSearch());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else {
					await this.searchForms()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangresOfSearch());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				}
				this.filter = copyFilter;
			});
		},
		firstColTitle() {
			if (this.currentChoice === 1) {
				return 'Menu name';
			} else if (this.currentChoice === 2) {
				return 'Message';
			} else {
				return 'Form name';
			}
		},
		secondColTitle() {
			if (this.currentChoice === 1) {
				return 'Menu number';
			} else if (this.currentChoice === 2) {
				return 'Message number';
			} else {
				return 'Form number';
			}
		},
		async appChanged(e) {
			if (e === 0) {
				this.webForm = true;
				this.langresType = 4;
			} else if (e === 1) {
				this.webForm = false;
				this.langresType = 3;
			}

			this.currentForm = 0; // reset to all forms
			this.currentPage = 1;
			this.filter = '';
			this.pageNumber = 0;
			this.searchSubmitted = false;
			this.isBusyAllScreen = true;

			await this.loadLangresForms()
				.then(async () => {
					this.setTotalRows(await this.getTotalRowsLangres());
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.setDefaultRow();
					this.isBusyAllScreen = false;
				});
		},
		async formChanged() {
			this.currentPage = 1;
			this.filter = '';
			this.pageNumber = 0;
			this.searchSubmitted = false;
			this.isBusyAllScreen = true;

			await this.loadLangresForms()
				.then(async () => {
					this.setTotalRows(await this.getTotalRowsLangres());
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.setDefaultRow();
					this.isBusyAllScreen = false;
				});
		},
		languageChanged() {
			this.choiceChanged();
		},
		async getTotalRowsLangres() {
			let records = [];

			switch (this.currentChoice) {
				case 0:
					records = await GetAllFormsContentForTranslation(this.currentLanguage, this.webForm, this.currentForm, this.showAllMessagesToVerify);

					break;
				case 1:
					records = await GetAllMenusContentForTranslation(this.currentLanguage, this.showAllMessagesToVerify);

					break;
				case 2:
					records = await GetAllMessagesForTranslation(this.currentLanguage, this.showAllMessagesToVerify);
					break;
			}

			return records.length;
		},
		async getTotalRowsLangresOfSearch() {
			let records = [];

			switch (this.currentChoice) {
				case 0:
					records = await searchtAllFormsContentForTranslation(
						this.currentLanguage,
						this.webForm,
						this.currentForm,
						this.showAllMessagesToVerify,
						this.filter
					);

					break;
				case 1:
					records = await searchAllMenusContentForTranslation(this.currentLanguage, this.showAllMessagesToVerify, this.filter);

					break;
				case 2:
					records = await searchAllMessagesForTranslation(this.currentLanguage, this.showAllMessagesToVerify, this.filter);
					break;
			}

			return records.length;
		},
		async handlePaginateAction() {
			let records = [];

			switch (this.currentChoice) {
				case 0:
					records = await GetAllFormsContentForTranslation(
						this.currentLanguage,
						this.webForm,
						this.currentForm,
						this.showAllMessagesToVerify,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 1:
					records = await GetAllMenusContentForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 2:
					records = await GetAllMessagesForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
			}

			return records;
		},
		async searchPaginateAction() {
			let records = [];

			switch (this.currentChoice) {
				case 0:
					records = await searchtAllFormsContentForTranslation(
						this.currentLanguage,
						this.webForm,
						this.currentForm,
						this.showAllMessagesToVerify,
						this.filter,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 1:
					records = await searchAllMenusContentForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.filter,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 2:
					records = await searchAllMessagesForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.filter,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
			}

			return records;
		},
		async getPaginateLangres() {
			let records = [];

			if (this.filter.trim() === '') {
				records = await this.handlePaginateAction();
			} else {
				records = await this.searchPaginateAction();
			}

			return records;
		},
		async loadLangresForms() {
			let results = await GetAllFormsContentForTranslation(
				this.currentLanguage,
				this.webForm,
				this.currentForm,
				this.showAllMessagesToVerify,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;

			if (this.Langres.length === 0) {
				this.newLangres.message = '';
				this.newLangres.translatedMessage = '';
			}
		},
		async searchForms() {
			let results = await searchtAllFormsContentForTranslation(
				this.currentLanguage,
				this.webForm,
				this.currentForm,
				this.showAllMessagesToVerify,
				this.filter,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;

			if (this.Langres.length === 0) {
				this.newLangres.message = '';
				this.newLangres.translatedMessage = '';
			}
		},
		async loadMenus() {
			let results = await GetAllMenusContentForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async searchMenus() {
			let results = await searchAllMenusContentForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.filter,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async loadMessages() {
			let results = await GetAllMessagesForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async searchMessages() {
			let results = await searchAllMessagesForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.filter,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async loadForms() {
			let results = await getAllFormsNameOnly(this.currentLanguage, this.webForm);

			this.Forms = results;
			this.Forms.unshift(Object.assign({}, this.allForms));

			for (let i = 0; i < this.Forms.length; i++) {
				this.Forms[i] = {
					...this.Forms[i],
					formName: `${this.Forms[i].formName} (${this.Forms[i].formNumber})`
				};
			}
		},
		async translateAll(language = null) {
			await translateAllItems(language);
		},
		async curLangresUpd() {
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			await AddUpdLangResItem(
				this.newLangres.type,
				this.newLangres.number,
				this.newLangres.value,
				this.currentLanguage,
				this.newLangres.translatedMessage,
				this.newLangres.paramFloat
			).then((result) => {
				if (result) {
					if (this.stateMessageAction === 1) {
						if (this.currentChoice === 0 || this.currentChoice === 1) {
							if (
								parseInt(this.newLangres.number, 10) === parseInt(this.copyLangres.number, 10) &&
								parseInt(this.newLangres.value, 10) !== parseInt(this.copyLangres.value, 10)
							) {
								this.stateMessageAction = 0;
							}
						} else if (this.currentChoice === 2) {
							if (parseInt(this.newLangres.number, 10) !== parseInt(this.copyLangres.number, 10)) {
								this.stateMessageAction = 0;
							}
						}
					}

					if (this.stateMessageAction === 1) {
						this.Langres[this.currentTableIndex].translatedMessage = this.newLangres.translatedMessage;
						this.Langres[this.currentTableIndex].paramFloat = this.newLangres.paramFloat;
						this.Langres[this.currentTableIndex].number = this.newLangres.number;
						this.Langres[this.currentTableIndex].value = this.newLangres.value;
						this.Langres[this.currentTableIndex].toCheck = false;

						this.createToastForMobile('Success', 'Message updated successfully', '');
					} else if (this.stateMessageAction === 0) {
						this.choiceChanged();

						this.createToastForMobile('Success', 'Message added successfully', '');

						this.newMessageInfo = {
							...this.newMessageInfo,
							message: result.message,
							number: result.number,
							value: result.value,
							factor: result.paramFloat
						};

						this.addedMessageAlert = true;
					}
					this.isSubmitted = false;
				}
			});
		},
		delItem(item) {
			this.$bvModal
				.msgBoxConfirm('Are you sure to remove this message ?', {
					title: 'Confirm',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					if (this.currentChoice === 0) {
						await deleteFormItem(item.formNumber, item.value, this.webForm)
							.then((result) => {
								if (result) {
									this.choiceChanged();
								}
							})
							.finally(() => {
								this.createToastForMobile('Success', 'Message removed successfully', '');
							});
					} else {
						await deleteLanguageItem(item.type, item.number, item.value)
							.then((result) => {
								if (result) {
									this.choiceChanged();
								}
							})
							.finally(() => {
								this.createToastForMobile('Success', 'Message removed successfully', '');
							});
					}
				})
				.catch((err) => console.log(err));
		},
		rowClicked(item, index, e) {
			this.currentTableIndex = !isNil(index) ? index : 0;

			this.stateMessageAction = 1;
			this.curLangres = item;
			this.copyLangres = Object.assign({}, item);
			this.newLangres = Object.assign({}, item);
		},
		calcManualTranslationCols(choice) {
			switch (choice) {
				case 0:
					this.numberCols = 6;
					this.valueCols = 6;
					this.factorCols = 0;
					this.helpCols = 0;

					break;
				case 1:
					this.numberCols = 4;
					this.valueCols = 3;
					this.factorCols = 3;
					this.helpCols = 2;

					break;
				case 2:
					this.numberCols = 12;
					this.valueCols = 0;
					this.factorCols = 0;
					this.helpCols = 0;

					break;
			}
		},
		selectGoogleTranslation(e) {
			this.valueGoogleSelected = e.length > 1 ? e.splice(e.length - 1, 1) : e;
		},
		setTotalRows(length) {
			this.totalRows = length;
		},
		selectRow(idx = 0) {
			this.$refs.myTable.selectRow(idx);
		},
		unselectRow(idx = 0) {
			this.$refs.myTable.unselectRow(idx);
		},
		setDefaultRow() {
			if (this.Langres.length > 0) {
				this.selectRow();
				this.rowClicked(this.Langres[0]);
			}
		},
		onPaginated(payload) {
			this.currentPage = payload;
			this.pageNumber = 0;
			this.isBusyAllScreen = false;

			this.$nextTick(async () => {
				await this.getPaginateLangres()
					.then((records) => {
						if (records) {
							this.Langres = records;
						}
					})
					.catch((e) => {
						console.error({ e });
					})
					.finally(() => {
						this.setDefaultRow();
					});
			});
		},
		clearFilter() {
			const text = this.filter.trim();

			if (text.length > 0 && this.searchSubmitted) {
				this.choiceChanged();
			} else {
				this.filter = '';
			}
		},
		removeFilter() {
			if (this.filter === '' && this.searchSubmitted) {
				this.choiceChanged();
			}
		},
		async search(e) {
			const removeSpaceQuote = /[\s'\/]+/g;
			const copyFilter = this.filter;
			this.filter = this.filter.trim().replace(removeSpaceQuote, '%');

			this.pageNumber = 0;

			if (this.filter === '') {
				return;
			}

			this.searchAction(copyFilter);
			this.searchSubmitted = true;
		},
		doTranslation(payload) {
			const translation = payload[0];

			this.$bvModal
				.msgBoxConfirm('Are you really sure to make translation ?', {
					title: 'Confirm',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) {
						this.valueGoogleSelected = [];

						return;
					}
					this.isBusyAllScreen = true;

					if (translation === 'all') {
						await this.translateAll()
							.then(() => {
								this.isBusyAllScreen = false;
							})
							.catch((e) => {
								console.error({ e });
								this.isBusyAllScreen = false;
								this.createToastForMobile(
									'Error',
									'An error was occured during google translation, please contact your administrator.',
									'',
									'danger'
								);
							})
							.finally(() => {
								this.choiceChanged();
								this.createToastForMobile('Success', 'Translation done successfully', '');
							});
					} else {
						await translateLanguageItems(parseInt(translation, 10))
							.then(() => {
								this.isBusyAllScreen = false;
							})
							.catch((e) => {
								console.error({ e });
								this.isBusyAllScreen = false;
								this.createToastForMobile(
									'Error',
									'An error was occured during google translation, please contact your administrator.',
									'',
									'danger'
								);
							})
							.finally(() => {
								this.choiceChanged();
								this.createToastForMobile('Success', 'Translation done successfully', '');
							});
					}
				})
				.catch((err) => {
					console.log(err);
					this.isBusyAllScreen = false;
				});
		},
		changeNumberRows() {
			this.choiceChanged();
		},
		newMessage() {
			this.stateMessageAction = 0;
			this.unselectRow();

			this.newLangres.type = this.langresType;
			this.newLangres.number = 0;
			this.newLangres.value = 0;
			this.newLangres.translatedMessage = '';
			this.newLangres.message = '';
			this.newLangres.paramFloat = 0;
		},
		getShowAllMessagesToVerify(e) {
			this.choiceChanged();
		},
		async updateToCheckStatus(item, payload) {
			await updateLanguageCheckStatus(item.id, payload);
		},
		goToPage(e) {
			if (this.pageNumber > 0) {
				this.currentPage = this.pageNumber;
				this.handleAction(this.pageNumber);
			}
		},
		closeAlert() {
			this.addedMessageAlert = false;
		},
		getIconLucide(name) {
			return icons[name];
		}
	},
	async created() {
		await this.loadForms();
	},
	mounted() {
		this.$nextTick(async () => {
			this.isBusyAllScreen = true;
			this.calcManualTranslationCols(0);
			await this.loadLangresForms()
				.then(async () => {
					this.setTotalRows(await this.getTotalRowsLangres());
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.setDefaultRow();
					this.isBusyAllScreen = false;
				});
		});
	},
	validations: {
		newLangres: {
			translatedMessage: {
				required
			}
		}
	}
};
</script>
