var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-row",
        { staticClass: "container-layout px-0" },
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c(
              "h1",
              {
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  ,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(this.caption1) + "\n\t\t\t")]
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "12" } },
            [
              _c(
                "b-overlay",
                {
                  attrs: { show: _vm.isBusyAllScreen, rounded: "lg" },
                  on: {
                    "update:show": function ($event) {
                      _vm.isBusyAllScreen = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "animated fadeIn" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "card-border-blue-light",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "form content-card-collapsible" },
                            [
                              _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0",
                                },
                                [
                                  _c("legend", { staticClass: "card-inside" }, [
                                    _vm._v("Advanced search"),
                                  ]),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Choice",
                                                "label-for": "choice",
                                                "label-cols": 4,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options: _vm.choiceOptions,
                                                  size: "md",
                                                },
                                                on: {
                                                  change: _vm.choiceChanged,
                                                },
                                                model: {
                                                  value: _vm.currentChoice,
                                                  callback: function ($$v) {
                                                    _vm.currentChoice = $$v
                                                  },
                                                  expression: "currentChoice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "From English to",
                                                "label-for": "language",
                                                "label-cols": 4,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options:
                                                    _vm.optionsForLanguage,
                                                  size: "md",
                                                },
                                                on: {
                                                  change: _vm.languageChanged,
                                                },
                                                model: {
                                                  value: _vm.currentLanguage,
                                                  callback: function ($$v) {
                                                    _vm.currentLanguage = $$v
                                                  },
                                                  expression: "currentLanguage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.currentChoice == 0
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Application",
                                                    "label-for": "appChoice",
                                                    "label-cols": 4,
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options: _vm.Apps,
                                                      id: "appChoice",
                                                      size: "md",
                                                    },
                                                    on: {
                                                      change: _vm.appChanged,
                                                    },
                                                    model: {
                                                      value: _vm.currentApp,
                                                      callback: function ($$v) {
                                                        _vm.currentApp = $$v
                                                      },
                                                      expression: "currentApp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Form",
                                                    "label-for": "formChoice",
                                                    "label-cols": 4,
                                                  },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label: "formName",
                                                      options: _vm.Forms,
                                                      reduce: (option) =>
                                                        option.formNumber,
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      "option:selected":
                                                        _vm.formChanged,
                                                    },
                                                    model: {
                                                      value: _vm.currentForm,
                                                      callback: function ($$v) {
                                                        _vm.currentForm = $$v
                                                      },
                                                      expression: "currentForm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "card-inside fix-fieldset-card-inside-padding-bottom my-3 py-0",
                                  staticStyle: {
                                    "background-color": "#ffffff",
                                  },
                                },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      attrs: {
                                        show: _vm.addedMessageAlert,
                                        dismissible: "",
                                        fade: "",
                                        variant: "primary",
                                      },
                                      on: { dismissed: _vm.closeAlert },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-center align-items-center pb-2",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("-- Last New Message --"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center pb-2",
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pl-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pr-4" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v("Number"),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.newMessageInfo
                                                                .number
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.currentChoice !== 2
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "pr-4" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v("Value"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .newMessageInfo
                                                                    .value
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentChoice === 1
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Factor"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .newMessageInfo
                                                                  .factor
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center",
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "text-left" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Message"),
                                                ]),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                "« " +
                                                  _vm._s(
                                                    _vm.newMessageInfo.message
                                                  ) +
                                                  " »"
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("legend", { staticClass: "card-inside" }, [
                                    _vm._v("Translations"),
                                  ]),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pb-4",
                                          attrs: { cols: "9" },
                                        },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0",
                                            },
                                            [
                                              _c(
                                                "legend",
                                                { staticClass: "card-inside" },
                                                [
                                                  _vm._v(
                                                    "« " +
                                                      _vm._s(
                                                        _vm.newLangres.message
                                                      ) +
                                                      " »"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.translatedLabel,
                                                            "label-for":
                                                              "msgTranslated",
                                                            "label-cols": 2,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              class: {
                                                                "is-invalid":
                                                                  _vm.isSubmitted &&
                                                                  _vm.$v
                                                                    .newLangres
                                                                    .translatedMessage
                                                                    .$error,
                                                              },
                                                              attrs: {
                                                                type: "textarea",
                                                                rows: "2",
                                                                autocomplete:
                                                                  "off",
                                                                id: "msgTranslated",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newLangres
                                                                    .translatedMessage,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newLangres,
                                                                      "translatedMessage",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newLangres.translatedMessage",
                                                              },
                                                            }
                                                          ),
                                                          _vm.isSubmitted &&
                                                          !_vm.$v.newLangres
                                                            .translatedMessage
                                                            .required
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "invalid-feedback",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tMessage is required\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-row",
                                                [
                                                  _c("b-col", {
                                                    attrs: { cols: "2" },
                                                  }),
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: _vm.numberCols,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Number",
                                                                    "label-for":
                                                                      "resNumber",
                                                                    "label-cols": 3,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "number",
                                                                        id: "resNumber",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .newLangres
                                                                            .number,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.newLangres,
                                                                              "number",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "newLangres.number",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.currentChoice != 2
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: _vm.valueCols,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Value",
                                                                        "label-for":
                                                                          "resValue",
                                                                        "label-cols": 3,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              id: "resValue",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newLangres
                                                                                  .value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newLangres,
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newLangres.value",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.currentChoice == 1
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: _vm.factorCols,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Factor",
                                                                        "label-for":
                                                                          "factor",
                                                                        "label-cols": 3,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              id: "factor",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newLangres
                                                                                  .paramFloat,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newLangres,
                                                                                    "paramFloat",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newLangres.paramFloat",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.currentChoice == 1
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: _vm.helpCols,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "pull-right",
                                                                      attrs: {
                                                                        id: "show-modal",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.showModal = true
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-question-circle",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 d-flex flex-row justify-content-end pb-3",
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "outline-secondary",
                                                                block: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.newMessage,
                                                              },
                                                            },
                                                            [_vm._v("New")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.disableSave,
                                                                block: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.curLangresUpd,
                                                              },
                                                            },
                                                            [_vm._v("Save")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("b-col", { attrs: { cols: "3" } }, [
                                        _c(
                                          "fieldset",
                                          {
                                            staticClass:
                                              "card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0",
                                          },
                                          [
                                            _c(
                                              "legend",
                                              { staticClass: "card-inside" },
                                              [_vm._v("Google")]
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "pj-cb pb-2",
                                                        attrs: {
                                                          size: "lg",
                                                          value: "all",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.selectGoogleTranslation,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.valueGoogleSelected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.valueGoogleSelected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "valueGoogleSelected",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "All items in all Languages"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "pj-cb pb-3",
                                                        attrs: {
                                                          size: "lg",
                                                          value:
                                                            _vm.currentLanguage,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.selectGoogleTranslation,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.valueGoogleSelected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.valueGoogleSelected =
                                                              $$v
                                                          },
                                                          expression:
                                                            "valueGoogleSelected",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "All items in " +
                                                            _vm._s(
                                                              _vm.languageChosen
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 d-flex flex-row justify-content pb-3",
                                                staticStyle: {
                                                  "padding-top": "41px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "primary",
                                                          disabled:
                                                            _vm.disableGoogleTranslation,
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.doTranslation(
                                                              _vm.valueGoogleSelected
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Translate")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "card-inside fix-fieldset-card-inside-padding-bottom my-3 pt-0 pb-3",
                                },
                                [
                                  _c("legend", { staticClass: "card-inside" }, [
                                    _vm._v("Filters"),
                                  ]),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-1",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                label: "Per page",
                                                "label-for": "per-page-select",
                                                "label-cols-sm": "2",
                                                "label-cols-md": "2",
                                                "label-cols-lg": "2",
                                                "label-size": "sm",
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  id: "per-page-select",
                                                  options: _vm.pageOptions,
                                                  size: "sm",
                                                },
                                                on: {
                                                  change: _vm.changeNumberRows,
                                                },
                                                model: {
                                                  value: _vm.perPage,
                                                  callback: function ($$v) {
                                                    _vm.perPage = $$v
                                                  },
                                                  expression: "perPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "mb-0",
                                              attrs: {
                                                label: "Filter",
                                                "label-cols-sm": "2",
                                                "label-cols-md": "2",
                                                "label-cols-lg": "2",
                                                "label-align-sm": "left",
                                                "label-size": "sm",
                                                "label-for": "filterInput",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.focus",
                                                        modifiers: {
                                                          focus: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      id: "filterInput",
                                                      placeholder:
                                                        "Type to search...",
                                                      autocomplete: "off",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.search(
                                                          $event
                                                        )
                                                      },
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "delete",
                                                            [8, 46],
                                                            $event.key,
                                                            [
                                                              "Backspace",
                                                              "Delete",
                                                              "Del",
                                                            ]
                                                          )
                                                        )
                                                          return null
                                                        return _vm.removeFilter.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.filter,
                                                      callback: function ($$v) {
                                                        _vm.filter =
                                                          typeof $$v ===
                                                          "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                      },
                                                      expression: "filter",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "btn-search",
                                                        },
                                                        [
                                                          _vm.filter.length ===
                                                          0
                                                            ? _c(
                                                                _vm.getIconLucide(
                                                                  "Search"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  staticClass:
                                                                    "icon",
                                                                  attrs: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    size: 16,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                _vm.getIconLucide(
                                                                  "X"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  staticClass:
                                                                    "icon",
                                                                  attrs: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    size: 16,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.clearFilter,
                                                                  },
                                                                }
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "filterInput",
                                                            placement: "top",
                                                            triggers: "focus",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tType 'Enter' to launch search\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mt-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change:
                                                  _vm.getShowAllMessagesToVerify,
                                              },
                                              model: {
                                                value:
                                                  _vm.showAllMessagesToVerify,
                                                callback: function ($$v) {
                                                  _vm.showAllMessagesToVerify =
                                                    $$v
                                                },
                                                expression:
                                                  "showAllMessagesToVerify",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Show all messages to verify"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6", xl: "6" } },
                                    [
                                      _c("b-pagination", {
                                        staticClass: "mt-1 mb-2",
                                        attrs: {
                                          "total-rows": _vm.totalRows,
                                          "per-page": _vm.perPage,
                                          align: "fill",
                                          size: "md",
                                        },
                                        on: { change: _vm.onPaginated },
                                        model: {
                                          value: _vm.currentPage,
                                          callback: function ($$v) {
                                            _vm.currentPage = $$v
                                          },
                                          expression: "currentPage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2", xl: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Page n°",
                                            "label-for": "page-number",
                                            "label-cols": 4,
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              id: "page-number",
                                              min: "0",
                                              step: "1",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.goToPage($event)
                                              },
                                            },
                                            model: {
                                              value: _vm.pageNumber,
                                              callback: function ($$v) {
                                                _vm.pageNumber = $$v
                                              },
                                              expression: "pageNumber",
                                            },
                                          }),
                                          _c(
                                            "b-tooltip",
                                            {
                                              attrs: {
                                                target: "page-number",
                                                placement: "top",
                                                triggers: "focus",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Type 'Enter' to go on page number "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-end align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#00af8b" } },
                                        [
                                          _vm._v("Total rows: "),
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.totalRows)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", xl: "12" } },
                                    [
                                      _vm.$screen.width < 992
                                        ? _c("CardListBuilder", {
                                            attrs: {
                                              items: _vm.Langres,
                                              fields: _vm.fields,
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "actions",
                                                  fn: function (data) {
                                                    return [
                                                      _vm.isDev
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItem(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2154080107
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.$screen.width >= 992
                                        ? _c("b-table", {
                                            ref: "myTable",
                                            staticClass: "my-table",
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: {
                                              hover: _vm.hover,
                                              responsive: "sm",
                                              selectable: "",
                                              selectedVariant:
                                                _vm.selectedColor,
                                              "select-mode": _vm.selectMode,
                                              items: _vm.Langres,
                                              fields: _vm.fields,
                                              "sticky-header": "stickyHeader",
                                              "head-variant": _vm.hv,
                                              bordered: "",
                                              small: "",
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(toCheck)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              attrs: {
                                                                size: "lg",
                                                                disabled:
                                                                  data.item
                                                                    .translatedMessage ===
                                                                  "AA",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToCheckStatus(
                                                                      data.item,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  data.item
                                                                    .toCheck,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      data.item,
                                                                      "toCheck",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "data.item.toCheck",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(rem)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm.isDev
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItem(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1178524022
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.showModal
            ? _c(
                "div",
                [
                  _c("transition", { attrs: { name: "modal" } }, [
                    _c("div", { staticClass: "modal-mask" }, [
                      _c("div", { staticClass: "modal-wrapper" }, [
                        _c("div", { staticClass: "modal-dialog" }, [
                          _c("div", { staticClass: "modal-content" }, [
                            _c("div", { staticClass: "modal-header" }, [
                              _c("h4", { staticClass: "modal-title" }, [
                                _vm._v("HELP"),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showModal = false
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v("×")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _c("strong", [_vm._v("Value")]),
                              _vm._v(
                                " is the value of the menu item, if you want to create a new entry and do not care about this\n\t\t\t\t\t\t\t\t\tvalue: enter -1, it will be generated automatically "
                              ),
                              _c("br"),
                              _c("strong", [_vm._v("Factor")]),
                              _vm._v(
                                " is the attribute for CO2 factor in KGCO2/€ (from menu 900) for each text potentially appearing\n\t\t\t\t\t\t\t\t\tin an expense, "
                              ),
                              _c("br"),
                              _vm._v("\n\t\t\t\t\t\t\t\t\tor "),
                              _c("br"),
                              _c("strong", [_vm._v("Factor")]),
                              _vm._v(
                                " is the green category value (from menu 1016) for green tips categories related to menus number:\n\t\t\t\t\t\t\t\t\t"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1020 for tip of day user "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1021 for tip of day SME "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1022 for tip of day FILM "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1023 for tip of day AGENCY "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1024 for tip of day HOTEL\n\t\t\t\t\t\t\t\t\t"
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"GREEN", "value": "0" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"REDUCE/REUSE/RECYCLE TIPS", "value": "1" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"KITCHEN GREEN TIPS", "value": "2" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"ECO-FRIENDLY BATHROOM TIPS", "value": "3" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"HOME ENERGY SAVING TIPS", "value": "4" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"WATER-SAVING TIPS", "value": "5" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"ORGANIC GARDENING TIPS", "value": "6" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"GAS SAVING TIPS", "value": "7" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"GREEN TRAVEL TIPS", "value": "8" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"RESPONSIBLE ANIMAL ENCOUNTERS", "value": "9"\n\t\t\t\t\t\t\t\t'
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }